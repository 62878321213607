
          @import "src/scss/mixins/index.scss";
          // additional scss code can be added here
        
.base {
  display: flex;
  flex-direction: row;
  @apply bg-transparent rounded-lg p-4 gap-4;
}

.active {
  @apply bg-gray-200 rounded-lg p-4;
}