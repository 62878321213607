
          @import "src/scss/mixins/index.scss";
          // additional scss code can be added here
        
.wrapper {
  flex: 1;
  container-name: capsule;
  container-type: inline-size;
}

.container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  gap: 1rem;
}

.prefix, .suffix {
  align-self: center;
}

.title {
  font-weight: 600;
}

.subheader {
  @apply text-gray-600 text-base leading-4;
}

.alignStart {
  .prefix, .suffix {
    align-self: flex-start;
  }
}

@container capsule (width < 300px) {
  .container, .main {
    flex-direction: column;
  }
  
  .main {
    gap: 0.5rem;
  }
  
  .prefix, .suffix {
    align-self: flex-start;
  }
}