
          @import "src/scss/mixins/index.scss";
          // additional scss code can be added here
        
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply font-display;
}

html, body, #root {
  @apply h-full bg-gray-100;
}

.withBorder {
  @apply border border-gray-300;
}

button, .button {
  display: block;
  width: fit-content;
  @apply bg-white text-black border border-gray-300 font-bold py-2 px-4 rounded disabled:opacity-40;
}

input, select {
  @apply border border-gray-500 inline-block;
}

hr {
  @apply border-t my-8 border-gray-200;
}

h2 {
  @apply text-3xl font-bold leading-8;
}

h3 {
  @apply text-2xl;
}

h4 {
  @apply text-xl;
}

h5 {
  @apply text-lg;
}

section + section {
  @apply mt-16 md:mt-24;
}

.ani-icon {
  view-transition-name: icon;
}

.ani-title {
  view-transition-name: title;
}

.ani-container {
  view-transition-name: container;
}